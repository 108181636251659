import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store/index'

Vue.use(VueRouter)

const routes = [

  {
    path: '/',
    name: 'Landing',
    component: () => import('../views/Landing/Landing.vue')
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Auth/Login.vue')
  },
  {
    path: '/asign-order',
    name: 'AsignOrder',
    component: () => import('../views/AsignOrder.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/orders/create',
    name: 'CreateOrder',
    component: () => import('../views/Orders/Create.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/orders/:id/edit',
    name: 'EditOrder',
    component: () => import('../views/Orders/Edit.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/account',
    name: 'Account',
    component: () => import('../views/Account/Account.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/orders',
    name: 'OrdersList',
    component: () => import('../views/Orders/List.vue'),
    meta: {
      requiresAuth: true
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.afterEach((to, from) => {
  store.dispatch('showLoading')
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (store.getters.isLoggedIn) {
      next()
      return
    }
    next('/login')
  } else {
    next()
  }
})

export default router
