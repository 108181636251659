<template>
    <div id="app-wrapper">
        <Spinner v-if="this.$store.getters.loading"/>
        <Sidebar/>
        <main id="app-container">
            <router-view/>
        </main>
    </div>
</template>

<script>

import Sidebar from '../components/Partials/Sidebar'
import Spinner from '../components/Partials/Spinner'

export default {
  name: 'Template',
  components: {
    Sidebar,
    Spinner
  },
  mounted () {

  }
}
</script>
