<template>
  <div id="app">
    <Template v-if='this.$route.name !== "Login" && this.$route.name !== "Landing"'/>
    <router-view v-else/>
  </div>
</template>

<script>
import Template from './template/Template'

export default {
  name: 'app',
  components: {
    Template
  },
  created () {

  }
}
</script>
<style lang="scss">
  @import "assets/scss/app";
</style>
