<template>
    <div class="sidebar" id="app-sidebar">
        <div class="expand-menu">
            <div class="menu-toggler">
                <i class="fas fa-hamburger"></i>
                <span> {{ $t('menu.menu') }} </span>
            </div>
        </div>
        <ul class="menu-list">
            <li class="menu-list-item">
                <router-link to="/account">
                    <i class="fas fa-user-circle"></i>
                    <span> {{ $t('menu.my_account') }} </span>
                </router-link>
            </li>
            <li class="menu-list-item">
                <router-link to="/orders">
                    <i class="fas fa-history"></i>
                    <span> {{ $t('menu.orders_history') }} </span>
                </router-link>
            </li>
            <li class="menu-list-item">
                <router-link to="/orders/create">
                    <i class="fas fa-plus"></i>
                    <span> {{ $t('menu.new_order') }} </span>
                </router-link>
            </li>
            <li class="menu-list-item">
                <router-link to="/asign-order">
                    <i class="fas fa-truck"></i>
                    <span> {{ $t('menu.deliver_orders') }} </span>
                </router-link>
            </li>
            <li class="menu-list-item">
                <a href="#" @click="logout()">
                    <i class="fas fa-sign-out-alt"></i>
                    <span> {{ $t('menu.logout') }} </span>
                </a>
            </li>
        </ul>
    </div>
</template>
<script>

export default {
  name: 'Sidebar',
  data () {
    return {
    }
  },
  methods: {
    logout () {
      this.$store.dispatch('showLoading')
      this.$store.dispatch('logoutRequest').then((response) => {
        this.$router.push({ name: 'Login' })
      })
    }
  }

}
</script>
