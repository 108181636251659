import Vue from 'vue'

const mixins = Vue.mixin({
  methods: {
    formatPrice (price) {
      return parseFloat(price).toFixed(2)
    },
    addrDisplay (addr) {
      console.log(addr)
      return addr.address + ' bl.' + (addr.bl || '') + ' et.' + (addr.et || '')
    }
  },
  mounted () {

  },
  destroyed () {

  }
})

export default mixins
