import Vue from 'vue'
import Vuex from 'vuex'
import AuthService from '../api-services/login.service'
import axios from 'axios'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    loading: false,
    status: '',
    token: localStorage.getItem('token') || null,
    user: (localStorage.getItem('user')) ? JSON.parse(localStorage.getItem('user')) : null
  },
  getters: {
    loading: state => state.loading,
    user: state => state.user,
    isLoggedIn: state => !!state.token,
    status: state => state.status,
    token: state => state.token
  },
  mutations: {
    auth_request (state) {
      state.status = 'loading'
    },
    auth_success (state, token, user) {
      state.status = 'succes'
      state.token = token
      state.user = user
    },
    auth_error (state) {
      state.status = 'error'
    },
    auth_logout (state) {
      state.status = null
      state.token = null
      state.user = null
    },
    loading_change (state, payload) {
      state.loading = payload
    },
    USER_DETAILS (state, payload) {
      state.user = payload
    }
  },
  actions: {
    hideLoading ({ dispatch, commit }) {
      commit('loading_change', false)
    },
    showLoading ({ dispatch, commit }) {
      commit('loading_change', true)
    },
    login ({ commit }, user) {
      return new Promise((resolve, reject) => {
        commit('auth_request')
        AuthService.login(user)
          .then(resp => {
            const token = resp.data.data.token
            const user = JSON.stringify(resp.data.data.user)
            localStorage.setItem('user', user)
            localStorage.setItem('token', token)
            axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
            commit('auth_success', token, user)
            resolve(resp)
          })
          .catch((error) => {
            commit('auth_error', error)
            localStorage.removeItem('token')
            reject(error)
          })
      })
    },
    logoutRequest ({ commit, dispatch }) {
      return new Promise((resolve, reject) => {
        AuthService.logout()
          .then(resp => {
            commit('auth_logout')
            localStorage.removeItem('token') // clear your user's token from localstorage
            localStorage.removeItem('user') // clear your user's token from localstorage
            delete axios.defaults.headers.common['Authorization']
            resolve()
          })
          .catch(error => {
            console.log(error)
            reject(error)
          })
      })
    }

  },
  modules: {
  }
})
