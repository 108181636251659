<template>
    <div class="loading-screen-wrapper">
        <div class="cluster-box-loading">
            <div class="shape shape-1"></div>
            <div class="shape shape-2"></div>
            <div class="shape shape-3"></div>
            <div class="shape shape-4"></div>
        </div>
        <h2> {{ $t('placeholder.loading') }} </h2>
    </div>
</template>

<script>
export default {
  name: 'Spinner'
}
</script>

<style lang="css" scoped>
</style>
