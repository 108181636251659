import axios from 'axios'

export default {
  login (data) {
    return axios.post('/login/operator', data)
  },
  logout () {
    return axios.post('/logout/operator')
  },
  getAuthUser () {
    return axios.get('/user')
  },
  changePassword (data) {
    return axios.post('/auth/change-password', data)
  }

}
