import Vue from 'vue'
import App from './App.vue'
import BootstrapVue from 'bootstrap-vue'
// import VeeValidate from 'vee-validate'
import axios from 'axios'
import router from './router'
import store from './store'
import { i18n } from './plugins/i18n.js'
import localforage from 'localforage'
import vSelect from 'vue-select'
import './registerServiceWorker'
import Echo from 'laravel-echo'
import VueSweetalert2 from 'vue-sweetalert2'
import 'sweetalert2/dist/sweetalert2.min.css'
import mixins from './mixins/index'

axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'
axios.defaults.baseURL = 'https://classiasi.ro/om/api'
// axios.defaults.headers.common['X-localization'] = 'ro'
axios.defaults.responseType = 'json'
const token = localStorage.getItem('token') || null
if (token) {
  axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
}

localforage.config({
  driver: localforage.INDEXEDDB, // Force WebSQL; same as using setDriver()
  name: 'OrderManagementApp',
  version: 1.0,
  size: 4980736, // Size of database, in bytes. WebSQL-only for now.
  storeName: 'order-management-app', // Should be alphanumeric, with underscores.
  description: 'some description'
})

window._ = require('lodash')
window.$ = window.jQuery = require('jquery')

window.Pusher = require('pusher-js')
window.Echo = new Echo({
  broadcaster: 'pusher',
  key: 'aea1817f0f233a0d0150',
  cluster: 'eu',
  forceTLS: true
})

// Vue.use(VeeValidate)
Vue.component('v-select', vSelect)
Vue.use(BootstrapVue)
Vue.use(VueSweetalert2)
Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  mixins: [mixins],
  render: h => h(App)
}).$mount('#app')
